export const createResidentsAssociationRequest = (
  accessToken: string,
  residentsAssociation: {
    name: string;
    creator_name: string;
    email: string;
    prefecture: string;
    city_name: string;
    local_authority_code: string;
    serviced: boolean;
    memo: string;
  }
) => {
  return {
    accessToken,
    method: "POST",
    path: `/api/v1/admin/residents_associations`,
    data: {
      name: residentsAssociation.name,
      creator_name: residentsAssociation.creator_name,
      email: residentsAssociation.email,
      prefecture: residentsAssociation.prefecture,
      city_name: residentsAssociation.city_name,
      local_authority_code: residentsAssociation.local_authority_code,
      serviced: residentsAssociation.serviced,
      memo: residentsAssociation.memo,
    },
  };
};

export const updateResidentsAssociationRequest = (
  accessToken: string,
  residentsAssociation: {
    id: number;
    name: string;
    creator_name: string;
    email: string;
    prefecture: string;
    local_authority_code: string;
    serviced: boolean;
    memo: string;
  }
) => {
  return {
    accessToken,
    method: "PUT",
    path: `/api/v1/admin/residents_associations/${residentsAssociation.id}`,
    data: {
      name: residentsAssociation.name,
      creator_name: residentsAssociation.creator_name,
      email: residentsAssociation.email,
      prefecture: residentsAssociation.prefecture,
      local_authority_code: residentsAssociation.local_authority_code,
      serviced: residentsAssociation.serviced,
      memo: residentsAssociation.memo,
    },
  };
};
export const residentsAssociationsRequest = (accessToken: string) => {
  return {
    method: "GET",
    path: `/api/v1/admin/residents_associations`,
    accessToken,
  };
};

export const residentsAssociationRequest = (
  accessToken: string,
  id: number
) => {
  return {
    method: "GET",
    path: `/api/v1/admin/residents_associations/${id}`,
    accessToken,
  };
};

export const updateStatusAccountBankRequest = (
  accessToken: string,
  residentsAssociationId: number,
  status: boolean,
) => {
  return {
    accessToken,
    method: "PUT",
    path: `/api/v1/admin/residents_associations/${residentsAssociationId}/bank_accounts/update_status`,
    data: {
      status,
    },
  };
};
