import React from "react";
import { ResidentsAssociation } from "../../../types/state";
import styles from "./ResidentsAssociationForm.module.scss";
import Select from "react-select";
import { Prefecture } from "../../../definition";
import LoadingFullScreen from "../LoadingFullScreen";

export interface ResidentsAssociationFormProps {
  error: string;
  residentsAssociation: ResidentsAssociation;
  isEdit?: boolean;
  upsertResidentsAssociation: (
    residentsAssociation: ResidentsAssociation
  ) => void;
}

const validates: { [key: string]: (value: any) => string } = {};

validates["email"] = (val) => {
  if (val.email.trim() === "") {
    return "メールアドレスを入力してください。";
  }

  return val.email.match(
    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
  )
    ? ""
    : "正しいメールアドレスを入力してください。";
};

validates["name"] = (val) => {
  return val.name.trim() === "" ? "名前を入力してください。" : "";
};

validates["creator_name"] = (val) => {
  return val.creator_name.trim() === "" ? "申請者名を入力してください。" : "";
};

validates["prefecture"] = (val) => {
  return val.prefecture === "" ? "都道府県を入力してください。" : "";
};

validates["local_authority_code"] = (val) => {
  return val.local_authority_code === "" ? "市区町村を入力してください。" : "";
};

export const ResidentsAssociationForm: React.FC<ResidentsAssociationFormProps> = (
  props
) => {
  const [state, setState] = React.useState({
    ...props.residentsAssociation,
    isSubmitted: !!props.residentsAssociation?.name,
    errorMessages: {} as { [key: string]: string },
  });
  const [
    localAuthorityCodeOptions,
    setLocalAuthorityCodeOptions,
  ] = React.useState<{ [key: string]: string }>({});
  const [isLoadingGetArea, setIsLoadingGetArea] = React.useState(false);

  const onSubmit = React.useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      const errorMessages = {} as { [key: string]: string };
      Object.keys(validates).forEach((key) => {
        const ret = validates[key](state);
        if (ret) {
          errorMessages[key] = ret;
        }
      });
      if (Object.keys(errorMessages).length > 0) {
        setState((s) => ({ ...s, isSubmitted: true, errorMessages }));
        return;
      }
      props.upsertResidentsAssociation({
        ...state,
        city_name: state.local_authority_code,
        local_authority_code:
          localAuthorityCodeOptions[state.local_authority_code],
      });
    },
    [state, props]
  );

  const onChange = React.useCallback(
    (e: React.SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      e.preventDefault();
      const val = e.currentTarget.value;
      const key = e.currentTarget.name;
      const args = { [key]: val };
      const ret = validates[key] ? validates[key](args) : "";
      setState((prevState) => ({
        ...prevState,
        [key]: val,
        errorMessages: { ...prevState.errorMessages, [key]: ret },
      }));
    },
    []
  );

  const onBlur = (e: React.SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const valueTrim = e.currentTarget.value.trim();
    e.currentTarget.value = valueTrim;

    const key = e.currentTarget.name;
    const args = { [key]: valueTrim };
    const ret = validates[key] ? validates[key](args) : "";
    setState((prevState) => ({
      ...prevState,
      [key]: valueTrim,
      errorMessages: { ...prevState.errorMessages, [key]: ret },
    }));
  }

  const onChangePrefecture = (option: { value: string; label: string }) => {
    const name = "prefecture";
    const val = option?.value || "";
    const args = { [name]: val };
    const ret = validates[name](args);
    setState((prevState) => ({
      ...prevState,
      [name]: val,
      errorMessages: {
        ...prevState.errorMessages,
        [name]: ret,
      },
    }));
    const prefectureCode = Prefecture[val];

    setIsLoadingGetArea(true);
    fetch(`${process.env.REACT_APP_URL_AREA_JP}?area=${prefectureCode}`)
      .then((data) => {
        return data.json();
      })
      .then(({ data }) => {
        const localAuthorityCodes = (data || []).reduce(
          (
            value: { [key: string]: string },
            currentValue: { id: string; name: string }
          ) => {
            return {
              ...value,
              [currentValue.name]: currentValue.id,
            };
          },
          {}
        );
        setState((preState) => ({ ...preState, local_authority_code: "" }));
        setLocalAuthorityCodeOptions(localAuthorityCodes);
      })
      .catch(() => {})
      .finally(() => {
        setIsLoadingGetArea(false);
      });
  };

  return (
    <div>
      {isLoadingGetArea && <LoadingFullScreen />}
      <div className="mt-5 p-5 max-w-sm mx-auto bg-white rounded-xl shadow-md items-center space-x-4">
        <div className="text-center pt-10 text-xl font-medium text-black">
          新しく自治会を作成
        </div>
        <div className={"w-full max-w-xs"}>
          <form onSubmit={onSubmit}>
            {props.error && (
              <div className={"mt-5 " + styles.errorMessage}>{props.error}</div>
            )}
            <div className="mb-4">
              <label className="block mt-5">
                <span className="block text-gray-700 text-sm font-bold mb-2">
                  自治会名
                </span>
                <input
                  type="text"
                  name="name"
                  className={styles.input}
                  id="name"
                  value={state.name}
                  onBlur={onBlur}
                  onChange={onChange}
                  maxLength={255}
                />
              </label>
              {state.isSubmitted && state.errorMessages["name"] && (
                <div className={styles.errorMessage}>
                  {state.errorMessages["name"]}
                </div>
              )}
            </div>
            <div className="mb-4">
              <label className="block mt-5">
                <span className="block text-gray-700 text-sm font-bold mb-2">
                  申請者名
                </span>
                <input
                  type="text"
                  name="creator_name"
                  className={styles.input}
                  id="creator_name"
                  value={state.creator_name}
                  onBlur={onBlur}
                  onChange={onChange}
                  maxLength={255}
                />
              </label>
              {state.isSubmitted && state.errorMessages["creator_name"] && (
                <div className={styles.errorMessage}>
                  {state.errorMessages["creator_name"]}
                </div>
              )}
            </div>
            <div className="mb-4">
              <label className="block mt-5">
                <span className="block text-gray-700 text-sm font-bold mb-2">
                  自治会メールアドレス
                </span>
                <input
                  type="email"
                  name="email"
                  className={styles.input}
                  id="email"
                  value={state.email}
                  onBlur={onBlur}
                  onChange={onChange}
                  maxLength={255}
                />
              </label>
              {state.isSubmitted && state.errorMessages["email"] && (
                <div className={styles.errorMessage}>
                  {state.errorMessages["email"]}
                </div>
              )}
            </div>

            {!props.isEdit && (
              <>
                <div className="mb-4">
                  <label className="block mt-5">
                    <span className="block text-gray-700 text-sm font-bold mb-2">
                      自治会の都道府県
                    </span>
                    <Select
                      options={Object.keys(Prefecture).map((o) => ({
                        label: o,
                        value: o,
                      }))}
                      value={{
                        label: state.prefecture,
                        value: state.prefecture,
                      }}
                      className={styles.select}
                      onChange={(option) => {
                        if (option) {
                          onChangePrefecture(option);
                        }
                      }}
                    />
                  </label>
                  {state.isSubmitted && state.errorMessages["prefecture"] && (
                    <div className={styles.errorMessage}>
                      {state.errorMessages["prefecture"]}
                    </div>
                  )}
                </div>
                <div className="mb-4">
                  <label className="block mt-5">
                    <span className="block text-gray-700 text-sm font-bold mb-2">
                      自治会の市区町村
                    </span>
                    <Select
                      value={{
                        label: state.local_authority_code,
                        value: state.local_authority_code,
                      }}
                      options={Object.keys(localAuthorityCodeOptions || {}).map(
                        (o) => ({
                          label: o,
                          value: o,
                        })
                      )}
                      className={styles.select}
                      onChange={(option) => {
                        const name = "local_authority_code";
                        const val = option?.value || "";
                        const args = { [name]: val };
                        const ret = validates[name](args);
                        setState((prevState) => ({
                          ...prevState,
                          [name]: val,
                          errorMessages: {
                            ...prevState.errorMessages,
                            [name]: ret,
                          },
                        }));
                      }}
                      isDisabled={
                        Object.keys(localAuthorityCodeOptions || {}).length ===
                        0
                      }
                      isLoading={isLoadingGetArea}
                    />
                  </label>
                  {state.isSubmitted &&
                    state.errorMessages["local_authority_code"] && (
                      <div className={styles.errorMessage}>
                        {state.errorMessages["local_authority_code"]}
                      </div>
                    )}
                </div>
              </>
            )}
            <div className="mb-4">
              <label className="block mt-5">
                <span className="block text-gray-700 text-sm font-bold mb-2">
                  有効/無効
                </span>
                <div className="mt-2">
                  <div className="relative inline-block w-16 mr-2 align-middle select-none transition duration-200 ease-in">
                    <input
                      type="checkbox"
                      checked={state.serviced}
                      id={"toggle"}
                      name="toggle"
                      onChange={(event) => {
                        const name = "serviced";
                        const val = event.target.checked;
                        setState((prevState) => ({
                          ...prevState,
                          [name]: val,
                        }));
                      }}
                      className={`${styles.toggleCheckbox} absolute block w-6 h-6 rounded-full bg-white border-2 appearance-none cursor-pointer`}
                    />
                    <label
                      htmlFor="toggle"
                      className="block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
                    ></label>
                  </div>
                </div>
              </label>
            </div>
            <div className="mb-4">
              <label className="block mt-5">
                <span className="block text-gray-700 text-sm font-bold mb-2">
                  メモ
                </span>

                <textarea
                  name="memo"
                  className={styles.textArea}
                  id="memo"
                  value={state.memo}
                  onBlur={onBlur}
                  onChange={onChange}
                  placeholder="自治会に関するメモを残せます。登録日や大東建託側の担当者など"
                  maxLength={255}
                />
              </label>
              {state.isSubmitted && state.errorMessages["memo"] && (
                <div className={styles.errorMessage}>
                  {state.errorMessages["memo"]}
                </div>
              )}
            </div>
            <div className="p-10 grid grid-cols-1 gap-6">
              <button
                className={styles.btn}
                onClick={onSubmit}
                disabled={
                  Object.keys(state.errorMessages).filter(
                    (k) => state.errorMessages[k]
                  ).length > 0 && state.isSubmitted
                }
              >
                保存
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ResidentsAssociationForm;
